// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Roboto:300,400,500);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/css?family=Poppins:300,400,500,600,700);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrew.css);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/earlyaccess/opensanshebrewcondensed.css);"]);
exports.push([module.id, "@import url(https://fonts.googleapis.com/icon?family=Material+Icons);"]);
exports.push([module.id, "@import url(https://maxcdn.bootstrapcdn.com/font-awesome/4.5.0/css/font-awesome.min.css);"]);
// Module
exports.push([module.id, "", "",{"version":3,"sources":[],"names":[],"mappings":"","file":"fonts.scss"}]);
// Exports
module.exports = exports;
